<template>
  <div class="flex-w-col">
    <LearnHeader
      fPath='/learn/csharp/1'
      title='Intro'
    />

    <h2 class="mb-o-10">Why learn C#?</h2>
    <p class="mb-o-25">C# is one of the most popular programming languages and can be used for a variety of things, including mobile applications, game development, and enterprise software. Knowing C# opens a great deal of doors for you as a developer.</p>

    <h2 class="mb-o-10">Take-Away Skills</h2>
    <p class="mb-o-25">In this course, you&apos;ll be exposed to fundamental programming concepts using C# and start writing programs right away. You&apos;ll build several projects to help you practice and test your knowledge using quizzes.</p>

    <h2 class="mb-o-10">Upcoming Releases</h2>
    <p class="mb-o-25">We&apos;re continuing to build new and exciting content for this course! Keep an eye out in upcoming months for new modules that cover object-oriented programming (OOP), control flow, arrays, and loops.</p>
  </div>
</template>

<script>
import LearnHeader from '@/components/LearnHeader.vue'

export default {
  components: { LearnHeader },
}
</script>
